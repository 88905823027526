import "foundation-sites";
import "chosen-js";

// Admin

$(document).ready(function() {
  $(".chosen-select").chosen({ width: "250px" });

  // Add node button
  $(".add-node-button").click(function() {
    $(".add-node-form-wrapper").css("display", "flex");
    $(document).foundation(); // reapply listeners
    $(".chosen-select").chosen();
  });

  // Add user button
  $(".add-user-button").click(function() {
    $(".add-user-form-wrapper").css("display", "flex");
    $(document).foundation(); // reapply listeners
  });

  // Add node form
  // Toggle group-only fields
  $(".e-row.group-only").hide();

  $("select[name='node[node_type_id]']")
    .change(function() {
      var selectedValue = $(this)
        .find("option:selected")
        .html();

      // console.log(selectedValue);

      if (selectedValue == "Group/PI") {
        $(".e-row.group-only").show();
      } else {
        $(".e-row.group-only").hide();
      }
    })
    .trigger("change");
});
